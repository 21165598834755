// extracted by mini-css-extract-plugin
export var post = "blog-module--post--1Wjxc";
export var translate = "blog-module--translate--14Fqq";
export var taglist = "blog-module--taglist--3BNWF";
export var tagLink = "blog-module--tagLink--1Jf0Z";
export var toread = "blog-module--toread--1v5bR";
export var tagBox = "blog-module--tagBox---8gs4";
export var thumbnail = "blog-module--thumbnail--2RsCF";
export var title = "blog-module--title--2xkT7";
export var meta = "blog-module--meta--QrHsp";
export var content = "blog-module--content--2ojhm";