import React, { useState, useEffect } from "react";
import * as styles from "./styles.module.css";
import img from "./top.png";

const Scroll = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true);
  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };
  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });
  return (
    <div>
      {show && (
        <img
          src={img}
          alt="up arrow"
          className={styles.toTop}
          onClick={handleClick}
        />
      )}
    </div>
  );
};
export default Scroll;
