import * as React from "react";
import PostLink from "../PostLinks/post-links";
import * as styles from "./styles.module.css";

const RelatedPosts = ({ data, tag, id }) => {
  const Posts = data.edges
    .filter(
      (edge) => edge.node.frontmatter.tags[0] === tag && edge.node.id !== id
    )
    .map((edge) => (
      <div className={styles.post} key={edge.node.id}>
        <PostLink post={edge.node} />
      </div>
    ))
    .slice(0, 2);
  // remove present post
  // filter tag ids
  return (
    <div className={styles.box}>
      {Posts.length > 0 && <h2>Related Posts</h2>}
      <div className={styles.container}>{Posts}</div>
    </div>
  );
};

export default RelatedPosts;
