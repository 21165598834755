import React from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  // EmailShareButton,
  // EmailIcon,
  //   TwitterShareButton,
  //   TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import * as styles from "./styles.module.css";

const ShareButtons = ({ title, url, twitterHandle, tags }) => {
  return (
    <div className={styles.icons}>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={30} round={true} />
      </WhatsappShareButton>

      <FacebookShareButton url={url}>
        <FacebookIcon size={30} round={true} />
      </FacebookShareButton>

      {/* <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
      >
        <TwitterIcon size={30} round={true} />
      </TwitterShareButton> */}

      <LinkedinShareButton url={url}>
        <LinkedinIcon size={30} round={true} />
      </LinkedinShareButton>

      {/* <EmailShareButton
        openShareDialogOnClick
        url={url}
        body={"Carbae Blog || Carbae || " + title}
        subject={title}
      >
        <EmailIcon size={30} round={true} />
      </EmailShareButton> */}
    </div>
  );
};
export default ShareButtons;
