import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout/layout";
import * as styles from "./blog.module.css";
import Seo from "../components/Seo/seo";
import "@fontsource/lato";
import { kebabCase } from "lodash";
import Contact from "../components/contact/index";
import RelatedPosts from "../components/Related/relatedPosts";
import Scroll from "../components/scrollTop/Scroll";
import ShareButtons from "../components/ShareSocials/ShareButtons";

const Template = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.href : "";

  const { markdownRemark, posts } = data;
  const { frontmatter, html, timeToRead, id } = markdownRemark;
  return (
    <Layout>
      {(!!frontmatter.metaDescription || !!frontmatter.title) && (
        <Seo
          description={frontmatter.metaDescription}
          title={frontmatter.title}
          image={frontmatter.thumbnail}
          keywords={frontmatter.tags}
        />
      )}
      <article className={styles.post}>
        {!frontmatter.thumbnail && (
          <div className={styles.thumbnail}>
            <h1 className={styles.title}>{frontmatter.title}</h1>
            <div className={styles.meta}>{frontmatter.date}</div>
          </div>
        )}
        {!!frontmatter.thumbnail && (
          <div
            className={styles.thumbnail}
            style={{ backgroundImage: `url(${frontmatter.thumbnail})` }}
          >
            <h1 className={styles.title}>{frontmatter.title}</h1>
            <div className={styles.meta}>{frontmatter.date}</div>
          </div>
        )}
        <div className={styles.translate}>
          {/* <div>
            <Link
              to={`${
                frontmatter.language === "en"
                  ? `/ml${frontmatter.path}`
                  : `${frontmatter.path}`
              }`}
            >
              {frontmatter.language === "en"
                ? "Read In Malayalam"
                : "Read in English"}
            </Link>
          </div> */}
          <ShareButtons
            title={frontmatter.title}
            url={url}
            tags={frontmatter.tags}
          />
        </div>
        <p className={styles.toread}>{timeToRead} min read</p>
        {!!html && (
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
        {frontmatter.tags && frontmatter.tags.length ? (
          <div className={styles.tagBox} style={{ marginTop: `4rem` }}>
            {/* <h4>Tags</h4> */}
            <div className={styles.taglist}>
              {frontmatter.tags.map((tag) => (
                <div className={styles.tagLink} key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </article>
      <Contact title={frontmatter.title} />
      <RelatedPosts data={posts} id={id} tag={frontmatter.tags[0]} />
      <Scroll showBelow={250} />
    </Layout>
  );
};
export default Template;

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    posts: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: $language } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt(pruneLength: 250)
          frontmatter {
            language
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            tags
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      timeToRead
      id
      frontmatter {
        language
        date(formatString: "MMMM DD, YYYY")
        path
        title
        metaDescription
        thumbnail
        tags
      }
    }
  }
`;
