// extracted by mini-css-extract-plugin
export var content = "styles-module--content--1Jd1l";
export var thanks = "styles-module--thanks--2Od5k";
export var bold = "styles-module--bold--11Zrz";
export var field = "styles-module--field--hp4Ts";
export var label = "styles-module--label--3nKee";
export var labelSpan = "styles-module--labelSpan--8K4R4";
export var textControl = "styles-module--textControl--3dRrU";
export var control = "styles-module--control--3udz0";
export var menu = "styles-module--menu--1k59J";
export var input = "styles-module--input--1tNrm";
export var textarea = "styles-module--textarea--3_PaP";
export var button = "styles-module--button--3Lnza";
export var is_link = "styles-module--is_link--vQPZc";
export var mobbtn = "styles-module--mobbtn--2nABH";